import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PROVIDER_ID, WalletProvider, useInitializeProviders } from '@txnlab/use-wallet';
import { DefaultSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import Footer from '../components/Footer';
import { config } from '../config';

import { DeflyWalletConnect } from '@blockshake/defly-connect';
import { PeraWalletConnect } from '@perawallet/connect';
import '../styles/globals.css';

const queryClient = new QueryClient();

const Header = dynamic(() => import('../components/Header'), {
  ssr: false,
});

const Providers = dynamic(() => import('../components/Providers'), {
  ssr: false,
});

function MyApp({ Component, pageProps }) {
  const walletProviders = useInitializeProviders({
    providers: [
      { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
      { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
    ],
  });

  return (
    <>
      <DefaultSeo
        title={`${config.collectionName} - An Algorand Community and NFT Collection`}
        description={`Our Signature "OG" collection consists of 250 unique NFTs and our V2 collection will be 2,400 programmatically generated NFTs hosted on the Algorand
            blockchain.`}
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: config.domain,
          site_name: config.collectionName,
          images: [
            {
              url: `${config.domain}/twitter-share-image.png`,
            },
          ],
        }}
        twitter={{
          handle: '@MHeepow',
          site: '@MHeepow',
          cardType: 'summary_large_image',
        }}
      />
      <WalletProvider value={walletProviders}>
        <QueryClientProvider client={queryClient}>
          <Providers>
            <Header />
            <main>
              <Component {...pageProps} />
            </main>
            <Footer />
          </Providers>
        </QueryClientProvider>
      </WalletProvider>
    </>
  );
}

export default MyApp;
