import React from 'react';
import { config } from '../config';

function Footer() {
  return (
    <footer className='py-12 text-white text-opacity-60 site-footer bg-secondary-500'>
      <div className='space-y-6'>
        <p className='text-center'>
          {new Date().getFullYear()}{' '}
          <a
            href={config.twitter}
            target={'_blank'}
            rel='noreferrer'
            className='font-bold text-white transition-opacity duration-200 hover:opacity-50'
          >
            {config.collectionName}
          </a>{' '}
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='inline h-2 px-2' fill='currentColor'>
            <path d='M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z' />
          </svg>{' '}
          An Algorand NFT Collection
        </p>
        <p className='text-center'>
          Website designed and built by{' '}
          <a
            href='https://www.minnerlabs.com'
            target={'_blank'}
            rel='noreferrer'
            className='font-bold text-white transition-opacity duration-200 hover:opacity-50'
          >
            Minner Labs
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
